


















































// Core
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

// Interfaces
import {IWork, IWorkStruct} from '@store/works/Interface'
import {worksNamespace} from "@store/works";
import {WorksActionTypes, WorksMutationTypes} from "@store/works/Types";

@Component({
  name: 'WorkStructAside',
  components: {
    'v-no-content': () => import('@/components/NoContent.vue'),
  },
})

export default class WorkStructAside extends Vue {
  @Prop(Array) readonly workStructTree!: IWorkStruct[]
  @Prop(Object) readonly activeWorkStruct!: IWorkStruct
  @Prop({type: Boolean, default: true}) readonly loading!: boolean

  private workStructTransfer: IWorkStruct | null = null
  private workStructTransferIn: IWorkStruct | null = null

  @worksNamespace.State('works')
  private worksList!: IWork[]

  @worksNamespace.Mutation(WorksMutationTypes.M_UPDATE_WORK_STRUCT)
  private updateWorkStruct!: (payload: IWorkStruct) => void

  @worksNamespace.Action(WorksActionTypes.A_UPDATE_WORK_STRUCT)
  private updateWorkStructAction!: (payload: IWorkStruct) => Promise<any>

  private openWorkStruct(workStruct) {
    this.$emit('changeActiveWorkStruct', workStruct)
    // let id = this.$refs.tree.getCurrentNode().id
    // console.log(this.$refs.tree.getCurrentNode());
    // console.log(id)
    // let node = this.$refs.tree.getNode(id)
    // console.log(node);
    // node.expanded = true
    this.updateScrollBar()
  }

  @Watch('activeWorkStruct')
  private updateScrollBar() {
    setTimeout(() => {
      (this.$refs.scrollbar as any).update()
    }, 400)
  }

  private handleDropStruct(dropped, droppedIn, type) {
    this.workStructTransfer = {
      id: dropped.data.id,
      name: dropped.data.name,
      parentId: dropped.data.parentId,
      projectId: dropped.data.projectId,
    }

    if ((type === 'before' || type === 'after') && droppedIn.data.parentId) {
      this.workStructTransferIn = null
    } else {
      this.workStructTransferIn = droppedIn.data
    }

    this.$confirm('Вы уверены что хотите переместить структуру?', 'Внимание!', {
      confirmButtonText: 'Переместить',
      cancelButtonText: 'Отменить',
      type: 'info',
    }).then(() => {
      this.acceptDrop()
    }).catch(() => {
      this.cancelDrop()
    })
  }

  public expandWorkStructTree(id :number){
    if(this.$refs.tree){
      const node = (this.$refs.tree as any).getNode(id)
      node.expanded = true
    }
  }

  private acceptDrop() {
    if (!this.workStructTransfer) return

    let parentId: number | null = null

    if (this.workStructTransferIn) {
      parentId = this.workStructTransferIn.id
    }

    this.updateWorkStructAction({...this.workStructTransfer, parentId})
      .then(() => {
        this.$notify({title: 'Выполнено', message: 'Структура перемещена', type: 'success',})
      })
      .catch(error => {
        this.workStructTransfer &&  this.updateWorkStruct(this.workStructTransfer)
        this.$notify.error({title: 'Ошибка', message: error.response.data.message})
      })

    this.endDrop()
  }

  private cancelDrop() {
    if (this.workStructTransfer) {
      this.updateWorkStruct(this.workStructTransfer)
    }
    this.endDrop()
  }

  private endDrop() {
    this.workStructTransferIn = null
    this.workStructTransfer = null
  }

}
